import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from "firebase/app"
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBYynjUUbrEO3apxBn03AvTkQN7LCpZjwc",
  authDomain: "su-backend.firebaseapp.com",
  databaseURL: "https://su-backend.firebaseio.com",
  projectId: "su-backend",
  storageBucket: "su-backend.appspot.com",
  messagingSenderId: "381174545480",
  appId: "1:381174545480:web:ae45a76523506b7477ec7d",
  measurementId: "G-KV9BJMNSQN"
};

firebase.initializeApp(firebaseConfig)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
